import React, {FC} from "react";

import IconRainbow from '../../files/icon-rainbow.png';
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";
import {FlexColumn, FlexRow} from "../../../../../../shared/components/box";

import Gif1 from '../../files/1 Onboarding.gif';
import Gif2 from '../../files/2 Map view.gif';
import Gif3 from '../../files/3 Filtering.gif';
import Gif4 from '../../files/4 Create new hobby.gif';
import RowImages from '../../files/hobily-screens-row.png';
import Iphone from '../../files/iPhone 14 Pro mock.png';
import {Box, Typography} from "@mui/material";
import {WidthWrapper} from "../../../../../../app/widthWrapper";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";


export const HobbilyDesign: FC = () => {

    return (
        <>
            <CaseParagraph title={'Visual design'} icon={IconRainbow}>
                <FlexColumn sx={({breakpoints}) => ({
                    gap: '8.0rem',
                    [breakpoints.down(1024)]: {gap: '6.4rem'},
                    [breakpoints.down(768)]: {gap: '4.8rem'},
                })}>
                    {[
                        {
                            title: 'Onboarding screens',
                            description: 'As users open the Hobbily app, they\'re welcomed with an animation, followed by screens detailing the app\'s features. These screens provide an overview of what Hobbily offers. Users then have the option to create an account, continue with Google/Facebook, or log in to an existing account. During the account setup, users can explore and select from a diverse range of hobbies across various categories, including Sports, Leisure, Games, Art, and many more.',
                            image: Gif1,
                        },
                        {
                            title: 'Map view',
                            description: 'The Map view feature on Hobbily\'s main page allows users to easily explore nearby hobby events. Users can see markers on the map representing different events, and by clicking on these markers, they can view event details without leaving the main page. This user-friendly functionality ensures that users can quickly discover and engage with events that are close by and match their interests.',
                            image: Gif2,
                        },
                        {
                            title: 'Filtering',
                            description: 'Users on Hobbily can filter hobby events based on dates, distance, selected categories, and whether they prefer to find online or offline hobby events. These filters assist users in discovering events that align with their preferences and availability, enhancing the efficiency and personalization of event discovery on the platform.',
                            image: Gif3,
                        },
                        {
                            title: 'Create new hobby event',
                            description: 'On Hobbily, users have the ability to create their own hobby events with ease. They can specify whether the event should be public or private, choose relevant categories to classify the event, provide a description, select an image, set dates, and indicate if the event recurs. Once satisfied, users can publish their event, making it visible to other users on the platform. This comprehensive feature empowers users to organize and share their hobbies seamlessly within the Hobbily community.',
                            image: Gif4,
                        },
                    ].map(({title, description, image: gif}, index) => (
                        <FlexRow {...animationOnScroll()} key={title} sx={({breakpoints}) => ({
                            alignItems: 'center',
                            flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',

                            gap: '6.4rem',
                            [breakpoints.down(1440)]: {gap: '4.8rem'},
                            [breakpoints.down(1280)]: {gap: '3.2rem'},

                            [breakpoints.down(768)]: {flexDirection: 'column'},

                            '& > div.iphone': {
                                width: '30%',
                                [breakpoints.down(768)]: {width: 'min(90%, 24rem)'},
                            },
                        })}>
                            <FlexColumn sx={{gap: '3.2rem', justifyContent: 'center'}}>
                                <Typography variant={'subtitle1'}>{title}</Typography>
                                <Typography variant={'text1'}>{description}</Typography>
                            </FlexColumn>

                            <Box className={'iphone'} sx={{
                                position: 'relative', flexShrink: 0, overflow: 'hidden',
                                aspectRatio: '238 / 489', borderRadius: '19%/8%',
                            }}>
                                <img src={gif} alt={title} style={{
                                    position: 'absolute',
                                    top: '2%',
                                    left: '4.5%',
                                    width: '91%',
                                    height: 'auto',
                                    borderRadius: '11%/6%',
                                }}/>

                                <img src={Iphone} alt={title} style={{
                                    zIndex: 2, width: '100%', height: 'auto',
                                    position: 'relative',
                                }}/>
                            </Box>
                        </FlexRow>
                    ))}
                </FlexColumn>
            </CaseParagraph>

            <FlexColumn sx={({breakpoints}) => ({
                backgroundColor: '#E4EAEE',
                paddingBlock: '12rem',
                [breakpoints.down(1440)]: {paddingBlock: '10rem'},
                [breakpoints.down(768)]: {paddingBlock: '8rem'},
            })}>
                <WidthWrapper caseWrapper>
                    <FlexColumn {...animationOnScroll()} sx={({breakpoints}) => ({
                        gap: '6.4rem',
                        [breakpoints.down(1440)]: {gap: '4.8rem'},
                        [breakpoints.down(1280)]: {gap: '3.2rem'},
                    })}>
                        <Typography variant={'subtitle1'}>{'Tab menu and Settings'}</Typography>
                        <img src={RowImages} alt={'Tab menu and Settings'} style={{width: '100%', height: 'auto'}}/>
                    </FlexColumn>
                </WidthWrapper>
            </FlexColumn>
        </>
    )
};