import React, {FC} from "react";
import {Typography} from "@mui/material";

import Table1 from '../../files/managed-table-1.webp';
import Table2 from '../../files/managed-table-2.webp';
import IconMarket from '../../files/icon-market.png';
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";
import {FlexColumn} from "../../../../../../shared/components/box";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";

export const MarketAnalysis: FC = () => {

    return (
        <CaseParagraph title={'Market analysis'} icon={IconMarket}>
            <FlexColumn sx={({breakpoints}) => ({
                gap: '10.0rem',
                [breakpoints.down(768)]: {gap: '6.4rem'},
            })}>
                {[
                    {
                        text: 'I conducted a detailed market analysis of 5 company\'s products. In general, market research aims to understand purchasing behavior and preferences regarding products. It is a means of gathering demographic and attitudinal information to answer questions about whether there is a need for your product in the market, who will buy it, and what is the best way to reach your audience. Ultimately, it can provide insight into product viability and market strategy.',
                        image: Table1,
                    },
                    {
                        text: 'Many business leaders, product managers, and UX researchers use SWOT analysis templates to get a clear picture of internal and external factors influencing the success of a certain product or service. I also decided to look into this.',
                        image: Table2,
                    },
                ].map(({text, image}, index) => (
                    <FlexColumn {...animationOnScroll()} key={text} sx={({breakpoints}) => ({
                        gap: '6.4rem',
                        [breakpoints.down(768)]: {gap: '3.2rem'},
                    })}>
                        <Typography variant={'text1'}>{text}</Typography>
                        <img src={image} alt={`Table ${index}`} style={{maxWidth: '100%'}}/>
                    </FlexColumn>
                ))}
            </FlexColumn>
        </CaseParagraph>
    )
};