import React, {FC} from "react";
import {AppBarWrapper} from "../../../app/appBar";
import {HeyThere} from "./modules/heyThere";
import {Footer} from "../../../app/footer";
import {Cases} from "./modules/cases";

export const Work: FC = () => {


    return (
        <AppBarWrapper hideScrollTop>

            <HeyThere/>
            <Cases/>

            <Footer/>
        </AppBarWrapper>
    );
};