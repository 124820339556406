import React, {FC} from "react";

import User from '../../files/managed-user.webp';
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";
import IconUser from '../../files/icon-user.png';
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";

export const UserPersona: FC = () => {

    return (
        <CaseParagraph title={'User persona'} icon={IconUser}>
            <img {...animationOnScroll({type: 'fade-up'})} src={User} alt={'User persona'} style={{maxWidth: '100%'}}/>
        </CaseParagraph>
    )
};