import {FC} from "react";

import {TCaseProps} from "../../components/case";

// import {Regulait} from "../../../cases/regulait";
import {Hobbily} from "../../../cases/hobbily";
import {Managed} from "../../../cases/managed";
import {Food} from "../../../cases/food";
import {JoMalone} from "../../../cases/joMalone";

// import RegulaitImg from './files/main_regulait.png';
import HobbilyImg from './files/main_hobbily.png';
import ManagedImg from './files/main_managed.png';
import FruitsImg from './files/main_3d_food.png';
import JoMaloneImg from './files/main_jo_malone.png';


export const cases: (TCaseProps & {caseFC: FC})[] = [
    // {
    //     company: 'Regulait',
    //     title: "Regulait Document Editor",
    //     logo: RegulaitImg,
    //     description: 'Feature that centralizes all document-related tasks, ensuring smooth workflows and compliance.',
    //     tags: ['saas', 'b2b', 'research', 'wireframes', 'UX/UI design', 'interaction design'],
    //     href: '/regulait',
    //     caseFC: Regulait,
    // },
    {
        company: 'Hobbily',
        title: 'Hobbily - mobile app for hobby events',
        logo: HobbilyImg,
        description: 'Explore your hobbies, find hobby events nearby and connect with other users who share the same interests.',
        tags: ['b2c', 'mobile app', 'research', 'UX/UI design', 'interaction design'],
        href: '/hobbily',
        caseFC: Hobbily,
    },
    {
        title: 'Managed | Online invoicing platform',
        logo: ManagedImg,
        description: 'The invoicing process can be a daunting task. To meet these challenges, businesses are turning to accounts payable solutions to automate their invoice processing workflows.',
        tags: ['b2c', 'research', 'wireframes', 'UX/UI design', 'Design system'],
        href: '/managed',
        caseFC: Managed,
    },
    {
        title: '3D Food project',
        logo: FruitsImg,
        description: "Explore “3D Food Project”, where delicious fantasies come to life.\n" +
            "Each illustration blends creativity and precision, capturing the essence of these delightful treats in a captivating 3D format.",
        tags: ['illustrations'],
        href: '/3d-food-project',
        caseFC: Food,
    },
    {
        title: 'Jo Malone website redesign concept',
        logo: JoMaloneImg,
        description: "Inspired by the beauty of Jo Malone brand.\n" +
            "Jo Malone London might have grown to become one of the world’s best-known perfumeries.",
        tags: ['research', 'web design'],
        href: '/jo-malone',
        caseFC: JoMalone,
    },
];
