import React, {FC} from "react";
import {Typography} from "@mui/material";

import IconMarket from '../../files/icon-market.png';
import {FlexColumn} from "../../../../../../shared/components/box";
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";

import Competitors from '../../files/hobily-competitor-analysis.png';
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";


export const HobbilyCompetitors: FC = () => {


    return (
        <CaseParagraph title={'Competitors analysis'} icon={IconMarket}>
            <FlexColumn {...animationOnScroll()} gap={'0.8rem'}>
                <Typography variant={'text1'}>
                    The assessment of the strengths and weaknesses of current and potential competitors.
                </Typography>
                <Typography variant={'text1'}>
                    4 competitors were analyzed by me. I figured out that it is useful to analyze two groups of
                    applications: the first is related strictly to hobbies, and the second is mostly to events (both for
                    amateurs and professionals).
                </Typography>
            </FlexColumn>

            <img {...animationOnScroll({type: 'fade-up'})} src={Competitors} alt={'Competitors'} style={{height: 'auto', width: '100%'}}/>
        </CaseParagraph>
    )
};