import React, {FC} from "react";
import {Typography} from "@mui/material";

import IconOverview from '../../files/icon-overview.png';
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";
import {FlexColumn} from "../../../../../../shared/components/box";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";

export const HobbilyOverview: FC = () => {

    return (
        <CaseParagraph title={'Overview'} icon={IconOverview}>
            <FlexColumn {...animationOnScroll()} gap={'0.8rem'}>
                <Typography variant={'text1'}>
                    Owning a hobby can make our lives more interesting, bringing us joy and fun. Nowadays, we are fortunate to have many options to choose from, depending on our interests,  country, budget, etc. Doing what you like and spending your free time qualitatively is one of the keys to work-life balance.
                </Typography>
                <Typography variant={'text1'}>
                    I would like to present my new project, “Hobbily” app. I decided to create an app that allows people to take care of their hobbies by finding communities of people (hobby mates), seeing hobby events on a list or on a map, having chats, sharing with friends, and much more.
                </Typography>
            </FlexColumn>
        </CaseParagraph>
    )
};