import {FC} from "react";
import {WidthWrapper} from "../../../../../../app/widthWrapper";
import {CaseHeader} from "../../../../../../shared/components/caseHeader";
import {FlexColumn} from "../../../../../../shared/components/box";

export const JoMaloneHeader: FC = () => {


    return (
        <WidthWrapper caseWrapper>
            <FlexColumn sx={({breakpoints}) => ({
                width: '100%',

                mt: '9.4rem',
                gap: '8.0rem',
                [breakpoints.down(376)]: {
                    gap: '6.6rem',
                    mt: '5.4rem',
                },
            })}>
                <CaseHeader title={"Jo Malone Redesign concept"}
                            description={'Inspired by the beauty of Jo Malone brand.\n' +
                                'Jo Malone London might have grown to become one of the world’s best-known perfumeries.'}
                            secondaryDescription={'All photos belong to Jo Malone brand and are used only for presentation and non-commercial purposes.'}
                            footer={['2021', 'Web designer']}
                />
            </FlexColumn>

        </WidthWrapper>
    )
};