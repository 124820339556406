import React, {FC, useRef, useState} from "react";
import {Typography, useMediaQuery} from "@mui/material";

import IconSurvey from '../../files/icon-person-many.png';
import {FlexColumn, FlexRow} from "../../../../../../shared/components/box";
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";
import {Theme} from "@mui/material/styles";

import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";

import Survey1 from '../../files/hobbily-survey-panel-1.png';
import Survey2 from '../../files/hobbily-survey-panel-2.png';
import Survey3 from '../../files/hobbily-survey-panel-3.png';
import Survey4 from '../../files/hobbily-survey-panel-4.png';
import Survey5 from '../../files/hobbily-survey-panel-5.png';
import Survey6 from '../../files/hobbily-survey-panel-6.png';
import Survey7 from '../../files/hobbily-survey-panel-7.png';
import Survey8 from '../../files/hobbily-survey-panel-8.png';
import Survey9 from '../../files/hobbily-survey-panel-9.png';
import Survey10 from '../../files/hobbily-survey-panel-10.png';

import InWorking from '../../files/hobbily-in-working.png';
import {colors} from "../../../../../../app/theme/colors";
import IconResearch from "../../files/icon-circle-research.svg";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";


const images: string[] = [Survey1, Survey2, Survey3, Survey4, Survey5, Survey6, Survey7, Survey8, Survey9, Survey10];

export const HobbilySurvey: FC = () => {

    const inRow = useMediaQuery<Theme>(({breakpoints}) => breakpoints.down(768));

    const flickingRef = useRef<Flicking>(null);

    const [actualPic, setActualPic] = useState<number>(0);

    return (
        <CaseParagraph title={'Survey'} icon={IconSurvey}>
            <FlexColumn gap={'1.6rem'}>
                <FlexColumn {...animationOnScroll()} gap={'0.8rem'}>
                    <Typography variant={'text1'}>
                        44 people participated in an online survey that I conducted using Google forms.
                    </Typography>
                    <Typography variant={'text1'}>
                        I also have some general insights into what is important to keep in mind and what I tried to stick to: the major point is to create a survey that is easy to complete, making every question in a survey as simple as possible, I tried to give to the survey participants as many chances as possible to express their opinion - open questions as the part of the survey is the best way. I want to share with you some screenshots from the survey:
                    </Typography>
                </FlexColumn>

                {!inRow ? (
                    <FlexRow sx={({breakpoints}) => ({
                        flexWrap: 'wrap',

                        gap: '3.2rem',
                        '& img': {
                            width: 'calc(50% - 1.6rem)',
                        },

                        [breakpoints.down(1280)]: {
                            gap: '2.0rem',
                            '& img': {
                                width: 'calc(50% - 1.0rem)',
                            },
                        },
                    })}>
                        {images.map((image, index) => (
                            <img {...animationOnScroll({type: 'fade-up'})} key={index} src={image} alt={`Survey ${index}`}
                                 style={{height: 'auto'}}/>
                        ))}
                    </FlexRow>
                ) : (
                    <>
                        <Flicking {...animationOnScroll()}
                            ref={flickingRef}
                            align={"center"}
                            autoInit

                            onWillChange={e => {
                                setActualPic(e.index);
                            }}
                        >
                            {images.map((image, index) => (
                                <div key={index} style={{width: 'min(38rem, 90%)', paddingInline: '0.4rem'}}>
                                    <img src={image} alt={`Survey ${index}`}
                                         style={{height: 'auto', width: '100%'}}/>
                                </div>
                            ))}
                        </Flicking>

                        <FlexRow sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            {generateDots(actualPic)}
                        </FlexRow>
                    </>
                )}
            </FlexColumn>

            <FlexColumn {...animationOnScroll()} gap={'0.8rem'}>
                <Typography variant={'text1'}>
                    I also conducted qualitative research while studying in Ulpan. This provided me with valuable insights into the challenges and experiences of new repatriates, who often share similar struggles and interests. These insights have been incorporated into the design and functionality of the Hobbily app to better serve its target audience.
                </Typography>
                <Typography variant={'text1'}>
                    So, based on quantitative and qualitative research I have identified some important app features:
                </Typography>
                <ul style={{listStyleType: 'disc', marginLeft: '1.7rem'}}>
                    {[
                        'To provide a user relevant list of events, including location access for more accuracy;',
                        'Importance to learn about users\' hobbies and interests to make more relevant and individualized event recommendations;',
                        'To allow users to invite friends to events;',
                        'To receive notifications or email reminders for hobby events they plan to attend;',
                        'A distinction between offline and online events;',
                        'Filtered Searches: These allow users to locate events according to their schedule and preferences. (Date, location, category, etc.);',
                        'Accurate duration parameters for events (details for the hobby event if it\'s recurring).',
                    ].map(item =>
                        <li>
                            <Typography key={item} variant={'text1'}>{item}</Typography>
                        </li>
                    )}
                </ul>
            </FlexColumn>

            <img {...animationOnScroll({type: 'fade-up'})} src={InWorking} alt={'I am working'} style={{width: '100%', height: 'auto'}}/>

            <FlexColumn {...animationOnScroll()} sx={{
                borderRadius: '0.4rem',
                background: colors.bg_white,
                padding: '2.0rem',

                width: '100%',
            }}>
                <img src={IconResearch} alt={'IconResearch'} style={{height: '3.2rem', width: 'auto', alignSelf: 'flex-start'}}/>
                <Typography variant={'h3'} sx={{mt: '1.6rem', mb: '1.6rem'}}>{'Do you know?'}</Typography>
                <Typography variant={'text1'}>
                    The <span style={{fontWeight: 700}}>World Health Organization (WHO)</span> research shows that people with hobbies are less likely to suffer from low moods, stress, and depression. Engaging in community-based recreation improves self-esteem and social connection.
                </Typography>
            </FlexColumn>
        </CaseParagraph>
    )
};

// Функция для генерации точек для карусели
const generateDots = (actualPic: number) => {
    const totalPics = images.length;
    const maxDotsToShow = 5; // Максимальное количество отображаемых точек
    let startSlice = actualPic - Math.floor(maxDotsToShow / 2);
    startSlice = Math.max(0, startSlice);
    let endSlice = startSlice + maxDotsToShow;

    if (endSlice > totalPics) {
        endSlice = totalPics;
        startSlice = Math.max(0, endSlice - maxDotsToShow);
    }

    return images.slice(startSlice, endSlice).map((_, index) => {
        const realIndex = startSlice + index;
        const isActive = realIndex === actualPic;
        const isAdjacent = Math.abs(realIndex - actualPic) === 1; // Проверяем, является ли точка соседней к активной

        // Определяем размеры точек
        let size = '0.6rem'; // Маленький размер по умолчанию
        if (isActive) {
            size = '1.0rem'; // Большой размер для активной точки
        } else if (isAdjacent) {
            size = '0.8rem'; // Средний размер для соседних точек
        }

        return (
            <div
                key={realIndex}
                style={{
                    height: size,
                    width: size,
                    borderRadius: '50%',
                    background: isActive ? '#0080B1' : '#AFD0DD',
                    margin: '0 4px',
                    transition: 'height 0.3s ease-in-out, width 0.3s ease-in-out', // Плавный переход только для размеров
                }}
            />
        );
    });
};

