import React, {FC} from "react";
import {FlexColumn} from "../../../../../../shared/components/box";
import {Typography} from "@mui/material";
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";

import IconChallenge from '../../files/icon-exclamation.png';
import IconSolution from '../../files/icon-lamp.png';
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";

export const ChallengeSolution: FC = () => {

    return (
        <FlexColumn gap={'6.4rem'} sx={({breakpoints}) => ({
            // mt: '12rem',
            // [breakpoints.down(1280)]: {mt: '10rem'},
        })}>
            <CaseParagraph title={'Challenge'} icon={IconChallenge}>
                <FlexColumn {...animationOnScroll()}gap={'0.8rem'}>
                    <Typography variant={'text1'}>
                        The invoicing process can be a daunting task that requires considerable time to be resolved in the most efficient way. The rise of digital commerce has created new challenges, as everyone expects to receive electronic invoices. To meet these challenges, businesses are turning to accounts payable solutions to automate their invoice processing workflows.
                    </Typography>
                    <Typography variant={'text1'}>
                        My personal challenge in investigating and designing the Managed invoicing platform was to create a solution that not only streamlined the invoicing process but also prioritized the empowerment of small businesses. Recognizing the critical role of the landing page as the first impression for users, my focus was to develop a landing page that not only effectively communicated the platform's features and benefits but also resonated with the target audience.
                    </Typography>
                </FlexColumn>
            </CaseParagraph>

            <CaseParagraph title={'Solution'} icon={IconSolution}>
                <Typography {...animationOnScroll()} variant={'text1'}>
                    To address these challenges, I came up with the idea for an easy-to-setup, use, and customize invoicing platform called "Managed" that helps to streamline the entire invoicing process, I conceptualized and designed the logo and landing page entirely from scratch. As mentioned, my main task was to design the landing page for the "Managed" platform and highlight its features and advantages.
                </Typography>
            </CaseParagraph>
        </FlexColumn>
    )
};