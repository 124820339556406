import {FC} from "react";
import {WidthWrapper} from "../../../../../../app/widthWrapper";
import {CaseHeader} from "../../../../../../shared/components/caseHeader";
import {FlexColumn} from "../../../../../../shared/components/box";

import ManagedMainScreen from '../../files/managed-main-screen.webp';
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";

export const ManagedHeader: FC = () => {


    return (
        <WidthWrapper caseWrapper>
            <FlexColumn sx={({breakpoints}) => ({
                width: '100%',

                mt: '9.4rem',
                gap: '8.0rem',
                [breakpoints.down(376)]: {
                    gap: '6.6rem',
                    mt: '5.4rem',
                },
            })}>
                <CaseHeader title={'Managed | Online invoicing platform'}
                            description={'Managed is an innovative cloud billing and invoice management software for small business owners.'}
                            footer={['2023', 'Logo design, Web design', 'Research, Wireframing, Visual Design']}
                />

                <img {...animationOnScroll({type: 'fade-up'})} src={ManagedMainScreen} alt={'Managed Main Screen'} style={{width: '100%', height: 'auto'}}/>
            </FlexColumn>
        </WidthWrapper>
    )
};
