import {Box, styled} from "@mui/material";

export const FlexRow = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
}));

export const FlexColumn = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
}));