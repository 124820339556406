import {createTheme} from "@mui/material";
import {colors} from "./colors";


export const theme = createTheme({
    palette: {
        // background: {
        //     default: '#fff7ec',
        //     paper: '#fff3e5',
        // },
        //
        // primary: {
        //     main: colors.primaryButton,
        // },
        //
        // secondary: {
        //     main: '#f28641',
        //     contrastText: '#fff7ec',
        // },
        //
        // warning: {
        //     main: '#f66811',
        // },

        primary: {
            main: colors.primary,
        },
    },

    typography: {
        htmlFontSize: 10,
        fontFamily: 'DM Sans, Roboto, Helvetica, Arial, sans-serif',

        fontWeightBold: 700,
        fontWeightMedium: 500,
        fontWeightRegular: 400,
        fontWeightLight: 300,
    },

    shape: {
        // borderRadius: 6,
    },

    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    color: 'black !important',
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: '0.4rem',
                },
                text: ({theme}) => ({
                    backgroundColor: 'transparent !important',
                    position: 'relative',

                    padding: 0,
                    minWidth: 0,

                    fontWeight: 400,
                    fontSize: '1.8rem',
                    lineHeight: '2.344rem',
                    color: colors.Black,

                    '&::after': {
                        content: "''",
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: 0,
                        height: '0.2rem',
                        backgroundColor: colors.Black, /* Цвет линии */
                        transition: 'width 0.5s ease-in-out',
                    },
                    '&:hover::after, &.checked::after': {
                        width: '100%',
                    },
                }),
            },
        },

        MuiTypography: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '&.MuiTypography-h1': { fontSize: '7.2rem', fontWeight: 700 },
                    '&.MuiTypography-h2': { fontSize: '3.2rem', fontWeight: 700 },
                    '&.MuiTypography-h3': { fontSize: '2.2rem', fontWeight: 700 },

                    '&.MuiTypography-subtitle1': { fontSize: '2.4rem', fontWeight: 400, lineHeight: '140%' },
                    '&.MuiTypography-subtitle2': { fontSize: '2.0rem', fontWeight: 400, lineHeight: '140%' },

                    '&.MuiTypography-text1': { fontSize: '1.6rem', fontWeight: 400, lineHeight: '142%' },
                    '&.MuiTypography-text2': { fontSize: '1.4rem', fontWeight: 400, lineHeight: '140%' },

                    '&.MuiTypography-small': { fontSize: '1.0rem', fontWeight: 500, textTransform: 'uppercase' },
                    '&.MuiTypography-button': { fontSize: '1.8rem', fontWeight: 500, textTransform: 'none' },
                    '&.MuiTypography-logo': { fontSize: '2.0rem', fontWeight: 700 },
                    '&.MuiTypography-menu': { fontSize: '1.8rem', fontWeight: 400 },
                    '&.MuiTypography-decorative': { fontSize: '1.4rem', fontWeight: 500, letterSpacing: '0.084rem', textTransform: 'uppercase' },


                    [theme.breakpoints.down(1440)]: {
                        '&.MuiTypography-h1': { fontSize: '6.2rem', fontWeight: 700 },
                    },

                    [theme.breakpoints.down(768)]: {
                        '&.MuiTypography-h1': { fontSize: '3.2rem', fontWeight: 700 },
                        '&.MuiTypography-h2': { fontSize: '2.6rem', fontWeight: 700 },
                        '&.MuiTypography-h3': { fontSize: '2.0rem', fontWeight: 700 },

                        '&.MuiTypography-subtitle1': { fontSize: '2.0rem', fontWeight: 400 },
                        '&.MuiTypography-subtitle2': { fontSize: '1.8rem', fontWeight: 400 },

                        '&.MuiTypography-text1': { fontSize: '1.6rem', fontWeight: 400, lineHeight: '140%' },

                        '&.MuiTypography-button': { fontSize: '1.6rem', fontWeight: 500 },
                        '&.MuiTypography-menu': { fontSize: '2.4rem', fontWeight: 500 },
                        '&.MuiTypography-decorative': { fontSize: '1.4rem', fontWeight: 500, letterSpacing: '0.084rem', textTransform: 'uppercase' },
                    },
                }),
            },
        },
    },
});
