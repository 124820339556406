import React, {FC} from "react";

import {FlexColumn, FlexRow} from "../../../../../shared/components/box";
import {WidthWrapper} from "../../../../../app/widthWrapper";

import photo from '../../../../../shared/files/photo.png';
import {Button, Typography, useTheme} from "@mui/material";
import {animationOnScroll} from "../../../../../shared/animationOnScroll";


export const Anastasiia: FC = () => {

    const theme = useTheme();

    const download = () => {
        const a = document.createElement('a');
        a.href = '/files/Anastasiia Dolea CV 2024.pdf';
        a.download = 'Anastasiia Dolea CV 2024.pdf';
        a.click();
    };

    return (
        <WidthWrapper sx={{

        }}>
            <FlexRow sx={{
                alignItems: "center", justifyContent: "space-between",

                '& img': {
                    width: '42%',
                },

                mt: '10.3rem',
                gap: '12.5rem',
                [theme.breakpoints.down(1024)]: {
                    flexDirection: 'column-reverse',
                    gap: '6.4rem',

                    '& img': {
                        width: '100%',
                        objectFit: 'cover',
                        aspectRatio: '1',
                    },
                },
                [theme.breakpoints.down(768)]: {
                    mt: '9.8rem',
                },
            }}>
                <FlexColumn {...animationOnScroll()} sx={{gap: '3.2rem', minWidth: 'calc((100% - 10rem) / 2)'}}>
                    <Typography variant={'h2'}>Hey, I'm Anastasiia Dolea.</Typography>
                    <FlexColumn gap={'0.8rem'}>
                        <Typography variant={"text1"}>
                            A <span style={{fontWeight: 700}}>UX/UI</span> and <span style={{fontWeight: 700}}>Product Designer</span> with a love for crafting digital experiences. I moved to Israel 3 years ago, originally from Ukraine.
                        </Typography>
                        <Typography variant={"text1"}>
                            My passion lies in understanding user behavior and translating that into a design language that resonates with the end user. I am always looking for unconventional design challenges and areas where you can create something new and even innovative. I am always striving to improve my skills and stay up-to-date with the latest design trends and technologies.
                        </Typography>
                        <Typography variant={"text1"}>
                            When I’m not designing, you can find me reading, cooking, listening podcasts, riding bicycle or having fun with my friends.
                        </Typography>
                    </FlexColumn>

                    <Button variant={"outlined"} onClick={download} size={"large"} sx={{width: 'fit-content'}} color={"primary"}>
                        <Typography variant={'button'}>Download my CV</Typography>
                    </Button>
                </FlexColumn>

                <img {...animationOnScroll({type: 'fade-up'})} src={photo} alt={'portrait'} style={{maxWidth: '46.7rem'}}/>
            </FlexRow>
        </WidthWrapper>
    )
};