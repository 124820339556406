import React, {FC} from "react";
import {Typography} from "@mui/material";

import IconStory from '../../files/icon-story.png';
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";
import {FlexColumn} from "../../../../../../shared/components/box";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";

export const HobbilyPersonalStories: FC = () => {

    return (
        <CaseParagraph title={'Personal story'} icon={IconStory}>
            <FlexColumn {...animationOnScroll()} gap={'0.8rem'}>
                <Typography variant={'text1'}>
                    The major factor in choosing a hobby topic is my personal experience. My husband and I moved to Israel about a year and a half ago. We had some routine every day, mainly to set up our lives in a new country, but I realized that having a hobby seemed too complicated.
                </Typography>
                <Typography variant={'text1'}>
                    For example, my husband loves to play basketball and volleyball. He wanted to find people to play with, but there were already teams formed on the courts. And I like singing, I struggled to find people with similar interests, I was searching, writing, and asking in social media chats if somebody could advise me on vocal or singing groups or choirs.
                </Typography>
            </FlexColumn>
        </CaseParagraph>
    )
};