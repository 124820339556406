import React, {FC} from "react";

import IconTriangle from '../../files/icon-triangle.png';
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";
import WireframesImage from '../../files/hobily-wireframes.png';
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";


export const HobbilyWireframes: FC = () => {

    return (
        <CaseParagraph title={'Some wireframes'} icon={IconTriangle}>
            <img {...animationOnScroll({type: 'fade-up'})} src={WireframesImage} alt={'Wireframes'} style={{
                width: '100%',
                height: 'auto'
            }}/>
        </CaseParagraph>
    )
};