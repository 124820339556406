import React, {FC} from "react";
import {colors} from "../../../../app/theme/colors";
import {FlexColumn} from "../../../../shared/components/box";
import {Footer} from "../../../../app/footer";
import {AppBarWrapper} from "../../../../app/appBar";
import {JoMaloneHeader} from "./modules/1-header";
import {JoMaloneOverview} from "./modules/2-overview";

export const JoMalone: FC = () => {


    return (
        <AppBarWrapper backgroundColor={colors.bg_jo_malone}>
            <FlexColumn sx={({breakpoints}) => ({
                gap: '16rem',
                [breakpoints.down(1024)]: {gap: '14rem'},
                [breakpoints.down(768)]: {gap: '12rem'},
            })}>
                <JoMaloneHeader/>
                <JoMaloneOverview/>
            </FlexColumn>

            <Footer/>
        </AppBarWrapper>
    )
}