import {FC} from "react";
import {WidthWrapper} from "../../../../../../app/widthWrapper";
import {CaseHeader} from "../../../../../../shared/components/caseHeader";
import {FlexColumn} from "../../../../../../shared/components/box";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";

export const FoodHeader: FC = () => {


    return (
        <WidthWrapper caseWrapper>
            <FlexColumn {...animationOnScroll()} sx={({breakpoints}) => ({
                width: '100%',

                mt: '9.4rem',
                gap: '8.0rem',
                [breakpoints.down(376)]: {
                    gap: '6.6rem',
                    mt: '5.4rem',
                },
            })}>
                <CaseHeader title={"3D Food project"}
                            description={'Imagine being hungry. What first appears in your mind? \n' +
                                'Maybe it is a tasty burger, a bit crunchy or soft with the best sauce inside. Or would you prefer sushi? The cold, firm fish combined with rice, sauce, and other ingredients is truly one-of-a-kind and delicious. Maybe you are a sweet-tooth? Biscuits, pastries, candies, puddings you may like the most.\n' +
                                'I used Adobe Illustrator while creating these 3D food illustrations.'}
                            footer={['2022', 'Illustrations']}
                />
            </FlexColumn>
        </WidthWrapper>
    )
};