import React, {FC} from "react";

import IconGoal from '../../files/icon-goal.png';
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";
import {FlexColumn} from "../../../../../../shared/components/box";
import {Typography} from "@mui/material";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";


export const HobbilyResult: FC = () => {

    return (
        <CaseParagraph title={'Result'} icon={IconGoal}>
            <FlexColumn {...animationOnScroll()} gap={'0.8rem'}>
                <Typography variant={'text1'}>
                    Overall, I enjoyed the process, I put a lot of interest and effort into it.
                </Typography>
                <Typography variant={'text1'}>
                    Hobbily is the result of personal experiences, insights gained from user research, and a commitment to creating a platform that enriches lives, fosters connections, and promotes a healthy work-life balance through the pursuit of passions and interests. Of course, it is a dynamic project, continuously evolving based on ongoing user feedback and innovative ideas.
                </Typography>
            </FlexColumn>
        </CaseParagraph>
    )
};