import React, {FC} from "react";
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";

import LandingImage from '../../files/managed-landing.webp';
import IconPaper from '../../files/icon-paper.png';
import {WidthWrapper} from "../../../../../../app/widthWrapper";
import {FlexColumn} from "../../../../../../shared/components/box";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";

export const Landing: FC = () => {

    return (
        <FlexColumn gap={'6.4rem'}>
            <CaseParagraph title={'Landing page'} icon={IconPaper}>

            </CaseParagraph>
            <WidthWrapper>
                <img {...animationOnScroll({type: 'fade-up'})} src={LandingImage} alt={'Landing'} style={{width: '100%', height: 'auto'}}/>
            </WidthWrapper>
        </FlexColumn>
    )
};