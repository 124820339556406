import React, {Suspense, useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";

import {CircularProgress, CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";
import {SnackbarProvider} from "notistack";

import {SnackInit} from "./snack";
import {Snack} from "./snack/components/snack";
import {theme} from "./theme/theme";
import {RootRoutes} from "./shell/routes";

import appCss from "./index.module.css";

import AOS from 'aos';
import 'aos/dist/aos.css';


export const App = () => {

    useEffect(() => {
        AOS.init({
            duration: 400,
            easing: 'linear',
            delay: 100,
            once: true,
        });
    }, []);

    return (
        <Suspense fallback={
            <div style={{
                width: '100vw', height: '100vh',
                display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}>
                <CircularProgress size={48}/>
            </div>
        }>
            <div className={appCss.App}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>

                        <SnackbarProvider maxSnack={3}
                                          preventDuplicate
                                          hideIconVariant
                                          autoHideDuration={3000}
                                          anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'center',
                                          }}
                                          Components={{
                                              success: Snack,
                                              error: Snack,
                                              warning: Snack,
                                              info: Snack,
                                              default: Snack,
                                          }}

                        >
                            <SnackInit/>

                            <Router>
                                <RootRoutes/>
                            </Router>
                        </SnackbarProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>
        </Suspense>
    );
}
