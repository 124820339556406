import {FC, useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {paths} from "../../constants";
import {cases} from "../../domain/pages/work/modules/cases/cases";


export const RootRoutes: FC = () => {

    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Switch>
            {paths.map(path => (
                <Route key={path.title} path={path.path} component={path.component}/>
            ))}

            {cases.filter(item => Boolean(item.href)).map(item => (
                <Route key={item.title} path={item.href} component={item.caseFC}/>
            ))}

            <Redirect to={paths[0].path}/>
        </Switch>
    )
}