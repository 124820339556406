import {FC, PropsWithChildren} from "react";
import {FlexRow} from "../../shared/components/box";
import {useTheme} from "@mui/material";
import {SxProps} from "@mui/system/styleFunctionSx";


export const WidthWrapper: FC<PropsWithChildren<{sx?: SxProps, caseWrapper?: boolean}>> = ({children, sx, caseWrapper}) => {

    const {breakpoints} = useTheme();

    return (
        <FlexRow sx={{
            width: '100%',
            maxWidth: '144rem',
            marginInline: 'auto',

            paddingInline: '10rem',
            [breakpoints.down(1280)]: {
                paddingInline: '6rem',
            },
            [breakpoints.down(768)]: {
                paddingInline: '2rem',
            },

            ...sx,
        }}>
            {!caseWrapper ? children : (
                <FlexRow sx={({breakpoints}) => ({
                    width: '100%',
                    paddingInline: '0',
                    [breakpoints.up(768)]: {
                        // paddingInline: 'calc(584 / 2 * (100% - 768px) / (1920 - 768))',
                        paddingInline: 'calc(500 / 2 * (100% - 768px) / (1920 - 768))',
                    },
                })}>
                    {children}
                </FlexRow>
            )}
        </FlexRow>
    )
}