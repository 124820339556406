import {FC, ReactNode} from "react";
import {Box, Link, Typography} from "@mui/material";


type TContactButtonProps = {
    text: string;
    href: string;
    icon: ReactNode;
};

export const ContactButton: FC<TContactButtonProps> = ({text, href, icon}) => {

    return (
        <Link
            href={href}
            underline={'none'}
            target={href.includes('https://') ? "_blank" : undefined}
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.6rem'
            }}>
            <Box sx={{
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                height: '3.6rem', width: '3.6rem',
                borderRadius: '0.4rem',
                // backgroundColor: colors.additional1
            }}>
                {icon}
            </Box>

            <Typography variant={'text1'}>
                {text}
            </Typography>
        </Link>
    )
};