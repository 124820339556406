import {FC} from "react";
import {WidthWrapper} from "../../../../../../app/widthWrapper";
import {FlexColumn, FlexRow} from "../../../../../../shared/components/box";

import Lollipops from '../../files/Artboard 1@3x copy 1.png';
import CakeSmall from '../../files/Artboard 1 2.png';
import Burger from '../../files/Artboard 1@2x 2.png';
import Apple from '../../files/Artboard 1@3x 2.png';
import Sushi from '../../files/Artboard 1@3x 5.png';
import Ramen from '../../files/Artboard 1@3x 6.png';
import Cake from '../../files/cake 1.png';
import Eggs from '../../files/egg 3.png';
import Watermelon from '../../files/food watermelon 2.png';
import Donuts from '../../files/new bg@3x 1.png';
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";

export const FoodImages: FC = () => {


    return (
        <WidthWrapper caseWrapper>
            <FlexColumn sx={({breakpoints}) => ({
                gap: '3.2rem',
                overflow: 'hidden',

                [breakpoints.down(1280)]: {gap: '2.0rem'},
                [breakpoints.down(768)]: {gap: '1.6rem'},

                '& div.row': {
                    gap: '3.2rem',
                    overflow: 'hidden',
                    [breakpoints.down(1280)]: {gap: '2.0rem'},
                    [breakpoints.down(768)]: {gap: '1.6rem'},
                },

                '& img': {width: '100%', height: 'auto'},
                '& div.row img': {width: '10px', height: 'auto', flexShrink: 1, flexBasis: '50%'},
            })}>
                <img {...animationOnScroll({type: 'fade-up'})} src={Eggs} alt={'Eggs'}/>
                <img {...animationOnScroll({type: 'fade-up'})} src={Apple} alt={'Apple'}/>

                <FlexRow {...animationOnScroll({type: 'fade-up'})} className={'row'}>
                    <img src={Donuts} alt={'Donuts'}/>
                    <img src={CakeSmall} alt={'CakeSmall'}/>
                </FlexRow>

                <img {...animationOnScroll({type: 'fade-up'})} src={Watermelon} alt={'Watermelon'}/>

                <FlexRow {...animationOnScroll({type: 'fade-up'})} className={'row'}>
                    <img src={Lollipops} alt={'Lollipops'}/>
                    <img src={Cake} alt={'Cake'}/>
                </FlexRow>

                <img {...animationOnScroll({type: 'fade-up'})} src={Ramen} alt={'Ramen'}/>

                <FlexRow {...animationOnScroll({type: 'fade-up'})} className={'row'}>
                    <img src={Burger} alt={'Burger'}/>
                    <img src={Sushi} alt={'Sushi'}/>
                </FlexRow>
            </FlexColumn>
        </WidthWrapper>
    )
};