import React, {FC} from "react";

import {FlexColumn, FlexRow} from "../../../../../../shared/components/box";

import IconChain from '../../files/icon-chain.png';

import Mockup1 from '../../files/managed-mockup-1.webp';
import Mockup2 from '../../files/managed-mockup-2.webp';
import Mockup3 from '../../files/managed-mockup-3.webp';
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";

export const Mockups: FC = () => {

    return (
        <CaseParagraph title={'Mockups'} icon={IconChain}>
            <FlexColumn sx={({breakpoints}) => ({
                width: '100%',
                overflow: 'hidden',

                gap: '3.2rem',
                [breakpoints.down(1280)]: {gap: '2.0rem'},
            })}>
                <img {...animationOnScroll({type: 'fade-up'})} src={Mockup3} alt={'Mockup3'} style={{width: '100%', height: 'auto', borderRadius: '0.4rem'}}/>

                <FlexRow sx={({breakpoints}) => ({
                    width: '100%',
                    overflow: 'hidden',

                    gap: '3.2rem',
                    '& img': {
                        width: '50%',
                        height: 'auto',
                    },

                    [breakpoints.down(375)]: {
                        flexDirection: 'column',
                        gap: '2.0rem',

                        '& img': {
                            width: 'auto',
                            height: 'auto',

                            objectFit: 'cover',
                        },

                        '& img.Mockup1': {
                            objectPosition: '50% 25%',
                            maxHeight: '90vw',
                        },
                        '& img.Mockup2': {
                            objectPosition: '50% 0%',
                            maxHeight: '80vw',
                        },
                    },
                })}>
                    <img {...animationOnScroll({type: 'fade-up'})} src={Mockup1} alt={'Mockup1'} className={'Mockup1'} style={{borderRadius: '0.4rem'}}/>
                    <img {...animationOnScroll({type: 'fade-up'})} src={Mockup2} alt={'Mockup2'} className={'Mockup2'} style={{borderRadius: '0.4rem'}}/>
                </FlexRow>
            </FlexColumn>
        </CaseParagraph>
    )
};