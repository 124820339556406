export const colors = {
    White: '#FFFFFF',
    Black: '#000000',
    Grey_light: 'rgba(201, 201, 201, 1)',
    Work_grey: '#858585',
    Work_stroke: '#494949',

    caseBackground: '#EDEDED',
    appBarBackground: 'rgba(237, 237, 237, 0.87)',

    bg_main: '#E2E2E2',
    bg_managed: '#EDEFF8',
    bg_regulait: '#F4F7F7',
    bg_jo_malone: '#F8F4EF',
    bg_food: '#F7F8F1',
    bg_hobbily: '#ECEFEF',
    bg_gray_light: '#F7F7F7',
    bg_white: '#FFFFFF',

    decorative_green_dark: '#4D8063',
    decorative_lilac: '#C886D3',
    decorative_violet: '#4968D3',

    grey3: '#828282',

    grey_palette_white: '#FFFFFF',

    primary: '#3C5DD8',
    primary_blue: '#2962FF',

    stroke_dark_grey: '#E0E0E0',

    text_white: '#FFFFFF',
    text_dark: '#343440',
    text_grey: '#A19F9D',
    text_grey_dark: '#605E5C',
    text_grey_light2: '#D2D0CE',





    // appBarBackground: 'rgba(237, 237, 237, 0.87)',
    //
    // background: 'rgba(237, 237, 237, 0.87)',
    //
    //
    // workBackground: '#1F1F1F',
    //
    // additional1: '#CAD1EF',
    // additional2: '#3B3B3B',
    //
    // primaryButton: '#D9E8FF',
    // primaryButtonHover: '#AECCFA',
    //
    // secondaryButton: '#D9E8FF',
    // secondaryButtonHover: '#3B3B3B',
    //
    // managed: {
    //     appBar: '#9596F2DE',
    //     logoColor: '#2825A6',
    //     paragraph: '#D7D6F4',
    // }
}