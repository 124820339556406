import React, {FC} from "react";
import {Typography} from "@mui/material";

import IconResearch from '../../files/icon-research.png';
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";

import Stages from '../../files/hobily-user-journey.png';
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";


export const HobbilyJorney: FC = () => {


    return (
        <CaseParagraph title={'User journey map'} icon={IconResearch}>
            <Typography {...animationOnScroll()} variant={'text1'}>
                A user journey map can help designers empathize with users by allowing them to "think in their shoes".
            </Typography>

            <img {...animationOnScroll({type: 'fade-up'})} src={Stages} alt={'User journey map'} style={{height: 'auto', width: '100%'}}/>
        </CaseParagraph>
    )
};