import React, {FC} from "react";
import {FlexColumn} from "../../../../../shared/components/box";
import {useTheme} from "@mui/material";
import {cases} from "./cases";
import {Case} from "../../components/case";
import {WidthWrapper} from "../../../../../app/widthWrapper";

export const Cases: FC = () => {

    const theme = useTheme();

    return (
        <WidthWrapper>
            <FlexColumn sx={{
                mt: '10rem',
                width: '100%',

                gap: '8rem',
                [theme.breakpoints.down(1280)]: {
                    gap: '6rem',
                },
            }}>
                {cases.map(props => <Case key={props.company} {...props}/>)}
            </FlexColumn>
        </WidthWrapper>
    );
};