import {FC} from "react";
import {FlexColumn} from "../../../../../../shared/components/box";
import {Typography} from "@mui/material";
import {colors} from "../../../../../../app/theme/colors";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";

type TJoMaloneScreenProps = {
    title: string,
    image: string,
    flexBasis?: string
};

export const JoMaloneScreen: FC<TJoMaloneScreenProps> = ({title, image, flexBasis}) => {


    return (
        <FlexColumn {...animationOnScroll()} sx={({breakpoints}) => ({
            flexBasis: flexBasis,

            gap: '3.2rem',
            [breakpoints.down(1024)]: {gap: '2.0rem'},
        })}>
            <Typography variant={'decorative'} color={colors.Work_grey}>{title}</Typography>

            <img src={image} alt={title} style={{width: '100%', height: 'auto', boxShadow: '0 0 1.2rem 0 #E8E2DC'}}/>
        </FlexColumn>
    )
};