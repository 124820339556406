type AnimationOnScroll = {
    type?: 'fade' | 'fade-up'
    offset?: number
};
export const animationOnScroll: (data?: AnimationOnScroll) => object = (props) => {
    const {
        type = 'fade',
        offset = undefined,
    } = props || {};

    return {
        'data-aos': type || 'fade',
        'data-aos-offset': offset
    }
}