import React, {FC} from "react";
import {Typography} from "@mui/material";

import IconPerson from '../../files/icon-person-one.png';
import {FlexColumn, FlexRow} from "../../../../../../shared/components/box";
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";

import Renata from '../../files/hobily-renata-user.png';
import Joseph from '../../files/hobily-joseph-user.png';
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";


export const HobbilyUserPersonas: FC = () => {


    return (
        <CaseParagraph title={'User personas'} icon={IconPerson}>
            <FlexColumn gap={'6.4rem'}>
                <FlexColumn {...animationOnScroll()} gap={'0.8rem'}>
                    <Typography variant={'text1'}>
                        A user persona is a fictional character that represents the target audience.
                    </Typography>
                    <Typography variant={'text1'}>
                        The purpose of personas is to gather findings and use them to define a realistic character that is likely to use my product. By personifying users, it helps to visualize them as real people. I referred to their frustrations and goals.
                    </Typography>
                </FlexColumn>

                <FlexRow sx={({breakpoints}) => ({
                    gap: '3.2rem',
                    '& > img': {width: 'calc(50% - 1.6rem)'},

                    [breakpoints.down(1280)]: {
                        gap: '2.0rem',
                        '& > img': {width: 'calc(50% - 1.0rem)'},
                    },
                    [breakpoints.down(768)]: {
                        flexDirection: 'column',
                        '& > img': {width: '100%'},
                    },
                })}>
                    <img {...animationOnScroll({type: 'fade-up'})} src={Renata} alt={'Renata Biletsky'} style={{height: 'auto'}}/>
                    <img {...animationOnScroll({type: 'fade-up'})} src={Joseph} alt={'Joseph Hoffman'} style={{height: 'auto'}}/>
                </FlexRow>
            </FlexColumn>
        </CaseParagraph>
    )
};