import React, {FC, PropsWithChildren} from "react";
import {Typography} from "@mui/material";
import {WidthWrapper} from "../../../app/widthWrapper";
import {FlexColumn, FlexRow} from "../box";
import {animationOnScroll} from "../../animationOnScroll";


type TCaseParagraphProps = {
    title: string;
    icon: string;
    gap?: string;
    withoutOverflowHidden?: boolean;
};

export const CaseParagraph: FC<PropsWithChildren<TCaseParagraphProps>> = ({children, title, icon, gap, withoutOverflowHidden}) => {

    return (
        <WidthWrapper caseWrapper>
            <FlexColumn sx={{
                gap: gap || '3.2rem',
                overflow: withoutOverflowHidden ? undefined : 'hidden',
                width: '100%',
            }}>
                <FlexRow {...animationOnScroll()} sx={({breakpoints}) => ({
                    gap: '1.6rem',
                    alignItems: 'center',

                    '& img': {
                        width: 'auto',
                        height: '3.8rem',
                    },

                    [breakpoints.down(768)]: {
                        '& img': {
                            height: '3.0rem',
                        },
                    },
                })}>
                    <img src={icon} alt={title}/>
                    <Typography variant={'h2'}>{title}</Typography>
                </FlexRow>

                {children}
            </FlexColumn>
        </WidthWrapper>
    )
};