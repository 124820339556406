import React, {FC} from "react";
import {colors} from "../../../../app/theme/colors";
import {FlexColumn} from "../../../../shared/components/box";
import {Footer} from "../../../../app/footer";
import {AppBarWrapper} from "../../../../app/appBar";
import {HobbilyHeader} from "./modules/1-header";
import {HobbilyOverview} from "./modules/2-overview";
import {HobbilyPersonalStories} from "./modules/3-stories";
import {HobbilyMyProcess} from "./modules/4-myProcess";
import {HobbilySurvey} from "./modules/5-survey";
import {HobbilyUserPersonas} from "./modules/6-personas";
import {HobbilyCompetitors} from "./modules/7-competitors";
import {HobbilyArchitecture} from "./modules/8-info";
import {HobbilyJorney} from "./modules/9-map";
import {HobbilyWireframes} from "./modules/10-wireframes";
import {HobbilyDesign} from "./modules/11-design";
import {HobbilyResult} from "./modules/12-result";

export const Hobbily: FC = () => {


    return (
        <AppBarWrapper backgroundColor={colors.bg_hobbily}>
            <FlexColumn sx={({breakpoints}) => ({
                gap: '16rem',
                [breakpoints.down(1024)]: {gap: '14rem'},
                [breakpoints.down(768)]: {gap: '12rem'},
            })}>
                <HobbilyHeader/>
                <HobbilyOverview/>
                <HobbilyPersonalStories/>
                <HobbilyMyProcess/>
                <HobbilySurvey/>
                <HobbilyUserPersonas/>
                <HobbilyCompetitors/>
                <HobbilyArchitecture/>
                <HobbilyJorney/>
                <HobbilyWireframes/>
                <HobbilyDesign/>
                <HobbilyResult/>
            </FlexColumn>

            <Footer/>
        </AppBarWrapper>
    )
}