import {FC} from "react";
import {FlexColumn} from "../box";
import {Typography} from "@mui/material";
import {colors} from "../../../app/theme/colors";
import {animationOnScroll} from "../../animationOnScroll";

type CaseHeaderProps = {
    title: string;
    description: string;
    secondaryDescription?: string;
    footer: string[];
}

export const CaseHeader: FC<CaseHeaderProps> = ({title, description, secondaryDescription, footer}) => {

    return (
        <FlexColumn {...animationOnScroll()} sx={{gap: '2.0rem', width: '100%'}}>
            <Typography variant={"h2"}>{title}</Typography>

            {description.split('\n').map(line => (
                <Typography key={line} variant={"subtitle2"}>{line}</Typography>
            ))}

            {secondaryDescription?.split('\n').map(line => (
                <Typography variant={"text2"} sx={{color: colors.Work_grey}}>{line}</Typography>
            ))}

            <Typography variant={"text1"} sx={{color: colors.Work_grey, whiteSpace: 'break-spaces'}}>{footer.join('   •   ')}</Typography>
        </FlexColumn>
    )
}