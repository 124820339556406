import React, {FC} from "react";
import {FlexColumn, FlexRow} from "../../../../../shared/components/box";
import {colors} from "../../../../../app/theme/colors";
import {Link, Typography} from "@mui/material";
import {ArrowForward} from "@mui/icons-material";
import {animationOnScroll} from "../../../../../shared/animationOnScroll";


export type TCaseProps = {
    company?: string,
    title: string,
    logo: string,
    description: string,
    tags: string[],
    href?: string,
};

export const Case: FC<TCaseProps> = ({tags, logo, description, href, title, company = title}) => {

    return (
        <FlexRow {...animationOnScroll()} sx={({breakpoints}) => ({
            background: colors.caseBackground,
            gap: '3.2rem',
            overflow: 'hidden',

            padding: '4.4rem 3.6rem',
            flexDirection: 'row',

            [breakpoints.down(1280)]: {
                flexDirection: 'column',
            },

            [breakpoints.down(376)]: {
                padding: '3.6rem 2.2rem',
            },
        })}>
            <FlexColumn sx={{gap: '2.2rem', justifyContent: 'center'}}>
                <Typography variant={'h3'}>{title}</Typography>

                <FlexRow sx={{
                    flexWrap: 'wrap',
                    gap: '1.2rem',
                }}>
                    {tags.map(tag =>
                        <Typography key={tag} sx={{
                            fontSize: '1.1rem',
                            fontWeight: 500,
                            textTransform: 'uppercase',
                            padding: '0.6rem 1.2rem',
                            background: colors.Work_grey,
                            color: colors.text_white,
                            borderRadius: '0.4rem',
                        }}>{tag}</Typography>
                    )}
                </FlexRow>

                <FlexColumn sx={{gap: '0.8rem'}}>
                    {description.split('\n').map((line, index) =>
                        <Typography key={index} variant={'text1'}>{line}</Typography>
                    )}
                </FlexColumn>

                {!!href && (
                    <Link href={href} color={`${colors.primary} !important`} fontWeight={500} fontSize={'1.6rem'} sx={{gap: '1rem', display: 'flex'}} underline={"none"}
                        // target={'_blank'}
                    >
                        View project <ArrowForward/>
                    </Link>
                )}
            </FlexColumn>

            <Link className={'header'} href={href} underline={'none'}
                // target={'_blank'}
                  sx={{
                      maxHeight: '36rem',
                      height: '100%',
                      maxWidth: '52rem',
                      overflow: 'hidden',
                      flexShrink: 0,
                      alignSelf: 'center',
                  }}
            >
                <img src={logo} alt={company} style={{maxHeight: '100%', maxWidth: '100%', width: 'auto', height: 'auto'}}/>
            </Link>
        </FlexRow>
    );
};