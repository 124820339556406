import {FC} from "react";
import {ReactComponent as Text} from "./text.svg";
import {Box, useTheme} from "@mui/material";
import {animationOnScroll} from "../../../../../shared/animationOnScroll";

export const CircleText: FC = () => {

    const {breakpoints} = useTheme();

    return (
        <Box {...animationOnScroll({offset: -10000})}  sx={{
            position: 'absolute',
            bottom: '12vh',

            mt: '13rem',
            [breakpoints.down(768)]: {mt: '9.6rem'},
            [breakpoints.down(375)]: {mt: '14rem'},

            "@keyframes rotate": {
                "100%": {
                    transform: 'rotate(360deg)',
                }
            },

            '& svg *': {
                fill: 'black'
            },

            [breakpoints.down(1440)]: {
                transform: 'scale(0.8)',
            },

            [breakpoints.down(768)]: {
                transform: 'scale(0.6)',
            },

        }}>
            <Text className={'scroll-circle'} style={{transformOrigin: 'center', animation: 'rotate 10s linear infinite'}}/>
        </Box>
    )
};