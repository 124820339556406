import React, {FC, useEffect, useRef, useState} from "react";
import {Typography, useMediaQuery, useTheme} from "@mui/material";

import {FlexColumn} from "../../../../../shared/components/box";
import {WidthWrapper} from "../../../../../app/widthWrapper";
import {colors} from "../../../../../app/theme/colors";
import {CircleText} from "../../components/circleText";

import HeyThereIcon from "./hey_there.png";
import {animationOnScroll} from "../../../../../shared/animationOnScroll";

export const HeyThere: FC = () => {

    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down(1440));
    const verySmall = useMediaQuery(theme.breakpoints.down(768));
    const veryVerySmall = useMediaQuery(theme.breakpoints.down(410));
    const heyThereIcon = (
        <img
            src={HeyThereIcon} alt={'hey there icon'}
            style={{
                height: verySmall ? '4.2rem' : small ? '5.8rem' : '7.8rem',
                position: 'relative',
                top: `calc(50% - ${verySmall ? '2.1rem' : small ? '2.9rem' : '3.9rem'})`,
                marginLeft: '1.2rem'
            }}
        />
    )

    const ref = useRef<HTMLDivElement>(null);
    const [top, setTop] = useState('-30%');
    useEffect(() => {
        setTop(
            ref.current
                ? !veryVerySmall ? `-${ref.current.offsetHeight / 5 * 3}px` : `-${ref.current.offsetHeight / 4}px`
                : '-30%'
        )
    }, [ref.current?.offsetHeight, ref.current?.offsetWidth, veryVerySmall]);

    return (
        <FlexColumn sx={{
            alignItems: 'center', width: '100vw', height: '100vh', position: 'relative',
            justifyContent: 'center',
        }}>
            <WidthWrapper>
                <FlexColumn ref={ref} sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: `100%`,
                    position: 'relative',
                    top: top,
                }}>
                    <FlexColumn sx={{gap: '0.6rem', zIndex: 1}}>
                        <Typography {...animationOnScroll()} variant={'h1'} textAlign={"center"}>
                            Hey there! {heyThereIcon}
                        </Typography>
                        <Typography {...animationOnScroll()} variant={'h1'} textAlign={"center"}>
                            I am <span style={{color: colors.primary, position: 'relative'}}>Anastasiia Dolea</span>
                        </Typography>
                    </FlexColumn>

                    <Typography {...animationOnScroll()} variant={'subtitle2'} textAlign={"center"} mt={'1.6rem'} sx={{zIndex: 1, maxWidth: '94rem'}}>
                        A UX/UI and Product Designer. I am dedicated to creating digital experiences that are both functional and aesthetically pleasing. I bring a unique blend of creativity and attention to details to all my projects.
                    </Typography>
                </FlexColumn>
            </WidthWrapper>

            <CircleText/>
        </FlexColumn>
    )
};
