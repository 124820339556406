import React, {FC} from "react";
import {Typography} from "@mui/material";

import Invoice from '../../files/managed-invoices.webp';
import Dashboard from '../../files/managed-dashboard.webp';
import ClientBoard from '../../files/managed-client-board.webp';

import IconGoal from '../../files/icon-goal.png';
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";
import {FlexColumn} from "../../../../../../shared/components/box";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";


export const FocusArea: FC = () => {

    return (
        <CaseParagraph title={'Focus area'} icon={IconGoal}>
            <Typography {...animationOnScroll()} variant={'text1'}>
                As I mentioned before, my main focus was to develop a landing page for the "Managed" platform. After I reviewed and compared a lot of competitors' products, and their websites and in order to create a clearer concept, I decided to develop several main screens of this platform: the main dashboard, template view, invoicing board, and client board.
            </Typography>

            <FlexColumn sx={({breakpoints}) => ({
                gap: '10.0rem',
                [breakpoints.down(375)]: {gap: '6.4rem'},
            })}>
                {[
                    {
                        title: 'Invoice templates',
                        icon: Invoice,
                        description: 'Using an invoice template can save you time manually creating documents. Moreover, it reduces the risk of costly invoice mistakes. The "Managed" platform has different invoice templates that you can customize with your own logo, images, color scheme, and fonts.',
                    },
                    {
                        title: 'Main dashboard',
                        icon: Dashboard,
                        description: "A dashboard is a visual display of the main data. \n" +
                            "A dashboard receives information from a linked database and allows to monitor performance, create reports and set estimates and targets for future work. It’s configurable, allowing you the ability to choose which data you want to see and whether you want to include charts or graphs to visualize the numbers.",
                    },
                    {
                        title: 'Client board',
                        icon: ClientBoard,
                        description: "Manage unlimited clients effortlessly. Users can create multiple customer profiles to invoice them regularly, and having an access to all customer information in one place, would save them more time to get on with their job.",
                    },
                ].map(card => (
                    <FlexColumn {...animationOnScroll()} key={card.title} sx={{
                        gap: '3.2rem',
                    }}>
                        <Typography variant={'subtitle1'}>{card.title}</Typography>
                        <Typography variant={'text2'}>{card.description}</Typography>
                        <img src={card.icon} alt={card.title} style={{height: 'auto', width: '100%'}}/>
                    </FlexColumn>
                ))}
            </FlexColumn>
        </CaseParagraph>
    )
};