import React, {FC} from "react";
import {AppBarWrapper} from "../../../../app/appBar";
import {ManagedHeader} from "./modules/1-header";
import {ChallengeSolution} from "./modules/2-challengeSolution";
import {MarketAnalysis} from "./modules/3-market";
import {UserPersona} from "./modules/4-userPersone";
import {FlexColumn} from "../../../../shared/components/box";
import {Footer} from "../../../../app/footer";
import {FocusArea} from "./modules/5-focusArea";
import {Landing} from "./modules/6-landing";
import {Mockups} from "./modules/7-mockups";
import {colors} from "../../../../app/theme/colors";

export const Managed: FC = () => {

    return (
        <AppBarWrapper backgroundColor={colors.bg_managed}>
            <FlexColumn sx={({breakpoints}) => ({
                gap: '16rem',
                [breakpoints.down(1024)]: {gap: '14rem'},
                [breakpoints.down(768)]: {gap: '12rem'},
            })}>
                <ManagedHeader/>
                <ChallengeSolution/>
                <MarketAnalysis/>
                <UserPersona/>
                <FocusArea/>
                <Landing/>
                <Mockups/>
            </FlexColumn>

            <Footer/>
        </AppBarWrapper>
    )
}