import React, {forwardRef, useEffect} from "react";
import {CustomContentProps} from "notistack";
import {IconButton, SnackbarContent, Typography} from "@mui/material";
import {CheckCircle, Close, Error, Info, Warning} from "@mui/icons-material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

import {FlexRow} from "../../../../shared/components/box";
import {removeSnack} from "../../index";


interface SnackProps extends CustomContentProps {
    // allowDownload: boolean,
}

export const Snack = forwardRef<HTMLDivElement, SnackProps>((props, ref) => {
    const {
        id,
        message,
        variant,

        autoHideDuration,
        persist,
        // ...other
    } = props

    // Timer for auto close
    useEffect(() => {
        if (!persist && autoHideDuration) {
            const timer = setTimeout(() => {
                removeSnack(id);
            }, autoHideDuration);

            return () => clearTimeout(timer);
        }
    }, [id, persist, autoHideDuration]);

    // Styles and icons depending on variant
    const sx: SxProps<Theme> = () => ({
        paddingBlock: 0,
        border: 'none',
        maxWidth: 'min(40rem, 80vw)',

        '& .MuiSnackbarContent-message': {
            display: 'flex',
            gap: '0.8rem',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
        },

        // default
        backgroundColor: '#313131',
        color: 'white',

        ...(variant === 'success' && {
            backgroundColor: '#43a047',
        }),
        ...(variant === 'warning' && {
            backgroundColor: '#ff9800',
        }),
        ...(variant === 'error' && {
            backgroundColor: '#d32f2f',
        }),
        ...(variant === 'info' && {
            backgroundColor: '#2196f3',
        }),
    });


    return (
        <SnackbarContent key={id} ref={ref} variant={"outlined"} sx={sx}
                         elevation={0}
                         message={(
                             <>
                                 <FlexRow sx={{ alignItems: 'center', gap: '0.8rem', flexGrow: 1 }}>
                                     {variant === 'success' && <CheckCircle />}
                                     {variant === 'warning' && <Warning     />}
                                     {variant === 'error'   && <Error       />}
                                     {variant === 'info'    && <Info        />}

                                     <Typography variant={"subtitle1"}>{message}</Typography>
                                 </FlexRow>

                                 <IconButton onClick={() => removeSnack(id)} size={"small"}>
                                     <Close sx={{color: 'white'}}/>
                                 </IconButton>
                             </>
                         )}/>
    )
})
