import React, {FC} from "react";
import {Typography} from "@mui/material";

import IconOverview from '../../files/icon-overview.png';
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";
import {FlexColumn, FlexRow} from "../../../../../../shared/components/box";

import {JoMaloneScreen} from "../../components/joMaloneScreen";

import HomePage from "../../files/jomalone-homepage.png";
import ProductPage from "../../files/jomalone-product-page.png";
import ShopAllPage from "../../files/jomalone-shop-all.png";
import YourBagPage from "../../files/jomalone-your-bag.png";
import ShippingPage from "../../files/jomalone-shipping.png";
import ShopInfoPage from "../../files/jomalone-shop-info.png";
import LoginPage from "../../files/jomalone-login.png";
import Mobile1 from "../../files/jomalone-mobile-1.png";
import Mobile2 from "../../files/jomalone-mobile-2.png";
import Mobile3 from "../../files/jomalone-mobile-3.png";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";
import {colors} from "../../../../../../app/theme/colors";


export const JoMaloneOverview: FC = () => {

    return (
        <CaseParagraph title={'Overview'} icon={IconOverview}>
            <FlexColumn gap={'6.4rem'} sx={{
                '& img': {
                    // boxShadow: '0 0 1.2rem 0 #E8E2DC',
                },
            }}>
                <FlexColumn {...animationOnScroll()} gap={'0.8rem'}>
                    <Typography variant={'text1'}>
                        My redesign of Jo Malone's landing page aimed to elevate the brand's digital presence by offering users a seamless and immersive shopping experience. In my redesign of Jo Malone's landing page, I aimed to create an ambiance of luxury, sophistication, and charm inspired by the brand's iconic imagery and signature scents.
                    </Typography>
                    <Typography variant={'text1'}>
                        Continuing with my process, I conducted a thorough competitor analysis of similar products, immersing myself in the user experience to understand the flow for order creation. I created low-fidelity wireframes of the pages. Now, in my study case, I am eager to unveil the final result of my redesign.
                    </Typography>
                </FlexColumn>

                <FlexRow sx={({breakpoints}) => ({
                    gap: '6.4rem 3.2rem',

                    [breakpoints.down(1280)]: {
                        gap: '6.4rem 2.0rem',
                    },

                    [breakpoints.down(1024)]: {
                        gap: '3.2rem 2.0rem',
                    },

                    [breakpoints.down(768)]: {
                        flexDirection: 'column',
                    },
                })}>
                    <FlexColumn sx={({breakpoints}) => ({
                        flexBasis: '50%',
                        gap: '6.4rem 3.2rem',

                        [breakpoints.down(1280)]: {
                            gap: '6.4rem 2.0rem',
                        },

                        [breakpoints.down(1024)]: {
                            gap: '3.2rem 2.0rem',
                        },
                    })}>
                        <JoMaloneScreen title={'Home page'} image={HomePage}/>
                        <JoMaloneScreen title={'Product page'} image={ProductPage}/>
                    </FlexColumn>

                    <FlexColumn sx={({breakpoints}) => ({
                        flexBasis: '50%',
                        gap: '6.4rem 3.2rem',

                        [breakpoints.down(1280)]: {
                            gap: '6.4rem 2.0rem',
                        },

                        [breakpoints.down(1024)]: {
                            gap: '3.2rem 2.0rem',
                        },
                    })}>
                        <JoMaloneScreen title={'Shop all page'} image={ShopAllPage}/>
                        <JoMaloneScreen title={'Your bag page'} image={YourBagPage}/>
                        <JoMaloneScreen title={'Shipping page'} image={ShippingPage}/>
                        <JoMaloneScreen title={'Shop info page'} image={ShopInfoPage}/>
                        <JoMaloneScreen title={'Login page'} image={LoginPage}/>
                    </FlexColumn>
                </FlexRow>

                <FlexColumn {...animationOnScroll()} sx={({breakpoints}) => ({
                    flexBasis: '100%',

                    gap: '3.2rem',
                    [breakpoints.down(1024)]: {gap: '2.0rem'},
                })}>
                    <Typography variant={'decorative'} color={colors.Work_grey}>{'Mobile view'}</Typography>

                    <FlexRow sx={{
                        gap: '2.0rem',

                        '& img': {
                            width: '10px',
                            height: 'auto',
                            boxShadow: '0 0 1.2rem 0 #E8E2DC',

                            flexBasis: '33%',
                            flexShrink: 1,
                            flexGrow: 1,
                        },
                    }}>
                        <img src={Mobile1} alt={'Mobile view 1'}/>
                        <img src={Mobile2} alt={'Mobile view 2'}/>
                        <img src={Mobile3} alt={'Mobile view 3'}/>
                    </FlexRow>
                </FlexColumn>
            </FlexColumn>
        </CaseParagraph>
    )
};