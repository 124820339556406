import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import {
    AppBar as MuiAppBar,
    Box,
    Button,
    darken,
    Drawer,
    IconButton,
    Link,
    SpeedDial,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {ArrowForwardIos, Close, Menu} from "@mui/icons-material";
import {FlexRow} from "../../shared/components/box";
import {paths} from "../../constants";
import {colors} from "../theme/colors";
import {WidthWrapper} from "../widthWrapper";
import {useLocation} from "react-router-dom";

import appBarCss from './appBar.module.css';


export const appBarHeight = '6.6rem';

type TAppBarWrapperProps = {
    backgroundColor?: string;
    hideScrollTop?: boolean;
};

export const AppBarWrapper: FC<PropsWithChildren<TAppBarWrapperProps>> = ({children, backgroundColor, hideScrollTop}) => {

    const {pathname} = useLocation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(1024));

    const [showScrollTop, setShowScrollTop] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleDrawerToggle = () => {
        setIsMenuOpen((prevState) => !prevState);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleScroll = (event: WindowEventMap["scroll"]) => {
        const show = window.scrollY > 10 * 10;
        if (show !== showScrollTop) {
            setShowScrollTop(show);
        }

        setIsScrolled(window.scrollY > 10);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsMenuOpen(false);
    }, [isMobile]);

    return (
        <>
            <MuiAppBar component={"nav"}
                       elevation={0}
                       sx={{
                           backdropFilter: 'blur(0.6rem)',

                           backgroundColor: isScrolled ? colors.appBarBackground : 'transparent', // Белый фон, если страница прокручена
                           transition: 'background-color 0.3s', // Плавный переход цвета фона
                       }}>
                <Toolbar disableGutters sx={{minHeight: `${appBarHeight} !important`}}>
                    <WidthWrapper>
                        <Link href={'/'} variant={"logo"} noWrap underline={'none'}>
                            {'Anastasiia Dolea'}
                        </Link>

                        <FlexRow sx={{flexGrow: 1, height: '0.1rem'}}/>

                        {isMobile ? (
                            <IconButton onClick={handleDrawerToggle} size={"small"} sx={{
                                borderRadius: '0.4rem',
                                border: `0.06rem solid ${colors.Work_stroke}`,
                                background: colors.bg_white,
                                "& .MuiTouchRipple-root .MuiTouchRipple-child": {
                                    borderRadius: '0.4rem',
                                },
                            }}>
                                {isMenuOpen ? <Close/> : <Menu/>}
                            </IconButton>
                        ) : (
                            <FlexRow sx={{gap: '5.6rem'}}>
                                {paths.map(path => (
                                    <Button key={path.title} href={path.path} variant={"text"} className={path.path === pathname ? 'checked' : undefined}>
                                        <Typography variant={'menu'}>{path.title}</Typography>
                                    </Button>
                                ))}
                            </FlexRow>
                        )}
                    </WidthWrapper>
                </Toolbar>
            </MuiAppBar>

            <nav>
                <Drawer
                    anchor={"right"}
                    variant={"temporary"}
                    open={isMenuOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{keepMounted: true}}
                    sx={{
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box', width: '100%',
                            background: `${backgroundColor || colors.bg_main} !important`,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '2.4rem',
                        },
                        zIndex: theme.zIndex.appBar - 1,
                    }}
                >
                    {paths.map(path => (
                        <Button key={path.title} href={path.path}>
                            <Typography variant={"menu"} component={"div"} noWrap>
                                {path.title}
                            </Typography>
                        </Button>
                    ))}

                    <Toolbar sx={{minHeight: `${appBarHeight} !important`}}/>
                </Drawer>
            </nav>

            <Box component={"main"} className={appBarCss.AppBar} sx={{
                height: '100%', overflow: 'auto',
                background: backgroundColor || colors.bg_main,
            }}>
                {showScrollTop && !hideScrollTop && (
                    <SpeedDial
                        ariaLabel="Scroll to top"
                        sx={{
                            position: 'fixed', bottom: '2.5vw', right: '2.5vw',
                            '& button': {
                                boxShadow: 'none',
                            },
                        }}
                        icon={<ArrowForwardIos sx={{rotate: '-90deg'}} fontSize={"medium"}/>}
                        onClick={scrollToTop}
                        FabProps={{
                            sx: {
                                width: '4.2rem', height: '4.2rem',
                                bgcolor: colors.Grey_light,
                                '&:hover': {
                                    bgcolor: darken(colors.Grey_light, 0.1),
                                }
                            }
                        }}
                    />
                )}

                <Toolbar sx={{minHeight: `${appBarHeight} !important`}}/>

                {children}
            </Box>
        </>
    )
};