import React, {FC} from "react";
import {Typography} from "@mui/material";

import IconLamp from '../../files/icon-lamp.png';
import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";

import Architecture from '../../files/hobbily-information-architecture.svg';
import {WidthWrapper} from "../../../../../../app/widthWrapper";
import {FlexColumn} from "../../../../../../shared/components/box";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";


export const HobbilyArchitecture: FC = () => {


    return (
        <FlexColumn gap={'3.2rem'}>
            <CaseParagraph title={'Information architecture'} icon={IconLamp}>
                <Typography {...animationOnScroll()} variant={'text1'}>
                    At this stage, I needed to understand how the information in the app would be structured. Users should be able to quickly and easily find the information they’re looking for. Understanding what value the content provides them, and how they actually use the content is crucial.
                </Typography>
            </CaseParagraph>

            <WidthWrapper>
                <img {...animationOnScroll({type: 'fade-up'})} src={Architecture} alt={'Information architecture'} style={{height: 'auto', width: '100%'}}/>
            </WidthWrapper>
        </FlexColumn>

    )
};