import React, {FC} from "react";
import {Typography, useTheme} from "@mui/material";

import {WidthWrapper} from "../widthWrapper";
import {colors} from "../theme/colors";
import {FlexColumn} from "../../shared/components/box";

import {ContactButton} from "../../shared/components/contactButton";

import {ReactComponent as LinkedinIcon} from "../../shared/components/contactButton/files/linkedin.svg";
import {ReactComponent as PhoneIcon} from "../../shared/components/contactButton/files/phone.svg";
import {MailOutlineRounded} from "@mui/icons-material";
import {animationOnScroll} from "../../shared/animationOnScroll";
// import {ReactComponent as MailIcon} from "../../shared/components/contactButton/files/email.svg";

export const Footer: FC<{dividerColor?: string}> = ({dividerColor}) => {

    const theme = useTheme();

    return (
        <WidthWrapper sx={{
            flexDirection: 'column',
            pb: '3rem',

            mt: '12rem',
            [theme.breakpoints.down(375)]: {
                mt: '14rem',
            },
        }}>
            <FlexColumn {...animationOnScroll()} sx={{
                alignItems: 'center',
                gap: '3.2rem',
                borderTop: `0.1rem solid ${dividerColor || colors.text_grey_light2}`,
                pt: '4rem',
            }}>
                <Typography variant={'h2'}>
                    Let’s get in touch!
                </Typography>

                <FlexColumn gap={'0.0rem'} alignItems={'center'}>
                    <ContactButton href={'mailto:anastasiiadolea@gmail.com'} text={'anastasiiadolea@gmail.com'} icon={<MailOutlineRounded/>} />
                    <ContactButton href={'tel:+972586599697'} text={'(972) 58-659-9697'} icon={<PhoneIcon/>}/>
                    <ContactButton href={'https://www.linkedin.com/in/anastasiia-dolea-7b50b6200'} text={'Linkedin'} icon={<LinkedinIcon/>} />
                </FlexColumn>
            </FlexColumn>

            <Typography fontWeight={400} fontSize={'1.2rem'} textAlign={'center'}
                        sx={{mt: '8rem'}}
                        color={`${colors.grey3} !important`}
            >
                © 2024 by Anastasiia Dolea
            </Typography>
        </WidthWrapper>
    );
};