import React, {FC} from "react";
import {AppBarWrapper} from "../../../app/appBar";
import {Footer} from "../../../app/footer";
import {Anastasiia} from "./modules/anastasiia";

export const About: FC = () => {


    return (
        <AppBarWrapper hideScrollTop>

            <Anastasiia/>

            <Footer/>
        </AppBarWrapper>
    );
};