import {MutableRefObject, useEffect} from "react";
import {OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar} from "notistack";


export const snackbarRef: MutableRefObject<{
    sendSnack: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
    removeSnack: (key: string | number) => void;
}> = {
    current: {
        sendSnack: (...props) => {console.error('sendSnack is not defined', props); return ''},
        removeSnack: (...props) => {console.error('removeSnack is not defined', props)},
    }
};

export const removeSnack = (key: SnackbarKey) => {
    // console.log('removeSnack', key);
    snackbarRef.current?.removeSnack?.(key);
};
export const sendSnack: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey =
    (message, options ) => {
        // console.log('sendSnack', {message, options});
        return snackbarRef.current?.sendSnack?.(message, options);
    }

export const SnackInit = () => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    useEffect(() => {
        snackbarRef.current = {removeSnack: closeSnackbar, sendSnack: enqueueSnackbar};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};