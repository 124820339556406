import React, {FC} from "react";
import {Typography} from "@mui/material";

import IconProcess from '../../files/icon-sand-clock.png';

import IconEnergy from '../../files/icon-circle-energy.svg';
import IconFilter from '../../files/icon-circle-filter.svg';
import IconNotes from '../../files/icon-circle-notes.svg';
import IconResearch from '../../files/icon-circle-research.svg';

import {CaseParagraph} from "../../../../../../shared/components/caseParagraph";
import {FlexColumn, FlexRow} from "../../../../../../shared/components/box";
import {colors} from "../../../../../../app/theme/colors";
import {animationOnScroll} from "../../../../../../shared/animationOnScroll";

export const HobbilyMyProcess: FC = () => {

    return (
        <CaseParagraph title={'My process'} icon={IconProcess}>
            <FlexRow gap={'1.6rem'} sx={({breakpoints}) => ({
                [breakpoints.down(1024)]: {flexDirection: 'column'},
            })}>
                {[
                    {
                        title: 'Discover',
                        icon: IconResearch,
                        description: [
                            'Pain points',
                            'Target audience',
                            'Survey',
                        ],
                    },
                    {
                        title: 'Define',
                        icon: IconFilter,
                        description: [
                            'User personas',
                            'User journey map',
                            'Competitor analysis',
                        ],
                    },
                    {
                        title: 'Ideate',
                        icon: IconEnergy,
                        description: [
                            'Brainstorming',
                            'Information architecture',
                        ],
                    },
                    {
                        title: 'Design',
                        icon: IconNotes,
                        description: [
                            'Low fidelity wireframes',
                            'Prototype',
                            'Visual design',
                        ],
                    },
                ].map(card => (
                    <FlexColumn {...animationOnScroll()} key={card.title} sx={{
                        borderRadius: '0.4rem',
                        background: colors.bg_white,
                        padding: '2.0rem',

                        flexBasis: '25%',
                        flexGrow: 1,
                        flexShrink: 1,
                    }}>
                        <img src={card.icon} alt={card.title} style={{height: '4.6rem', width: 'auto', alignSelf: 'flex-start'}}/>
                        <Typography variant={'subtitle2'} sx={{mt: '1.8rem', mb: '1.2rem'}}>{card.title}</Typography>
                        <ul style={{listStyleType: 'disc', marginLeft: '1.7rem'}}>
                            {card.description.map(item =>
                                <li>
                                    <Typography key={item} variant={'text2'}>{item}</Typography>
                                </li>
                            )}
                        </ul>
                    </FlexColumn>
                ))}
            </FlexRow>
        </CaseParagraph>
    )
};