import React, {FC} from "react";
import {colors} from "../../../../app/theme/colors";
import {FlexColumn} from "../../../../shared/components/box";
import {Footer} from "../../../../app/footer";
import {AppBarWrapper} from "../../../../app/appBar";
import {FoodHeader} from "./components/1-header";
import {FoodImages} from "./components/2-images";

export const Food: FC = () => {


    return (
        <AppBarWrapper backgroundColor={colors.bg_food}>
            <FlexColumn sx={({breakpoints}) => ({
                // gap: '16rem',
                // [breakpoints.down(1024)]: {gap: '14rem'},
                // [breakpoints.down(768)]: {gap: '12rem'},

                gap: '8rem',
            })}>
                <FoodHeader/>
                <FoodImages/>
            </FlexColumn>

            <Footer/>
        </AppBarWrapper>
    )
}